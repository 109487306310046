import { graphql, useStaticQuery } from "gatsby"

import Fields from '../components/fields'
import Layout from '../layouts'
import PageHeader from '../components/page/header'
import Photos from '../components/photos'
import React from 'react'
import Seo from '../components/seo'
import Text from '../components/text'

const Therapy = () => {
  const { pageHeaderImage, fields, photoOne, photoTwo } = useStaticQuery(
    graphql`
      {
        pageHeaderImage: imageSharp(resize: {originalName: {eq: "schmerz-therapie.jpg"}}) {
          gatsbyImageData(
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1000
          )
        }

        photoOne: imageSharp(resize: {originalName: {eq: "schmerztherapie-augenliegenstuetze.jpg"}}) {
          gatsbyImageData(
            height: 710
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1110
          )
        }

        photoTwo: imageSharp(resize: {originalName: {eq: "schmerztherapie-physiokey.jpg"}}) {
          gatsbyImageData(
            height: 710
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1110
          )
        }

        fields: allSchmerztherapieJson {
          edges {
            node {
              name
              copy
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo
        title='Schmerztherapie'
        currentUrl='/schmerztherapie'
      />
      <PageHeader
        headline='Schmerztherapie'
        copy='Jede der Behandlungen ist individuell zugeschnitten und bringe durch die Kombination verschiedenster Methoden eine schnelle und effektive Schmerzlinderung.'
        image={pageHeaderImage}
      />
      <Text text='Das Konzept ist ganzheitlich und nachhaltig und soll Ihnen ein Leben ohne Schmerzen, Bewegungseinschränkungen und Medikamente ermöglichen. Die Arbeit an der Ursache und nicht am Symptom, ist maßgeblich für den Erfolg und bildet die Basis der Therapie.'/>
      <Fields fields={fields?.edges.map((node) => node.node )}/>
      <Text text={`
## Ablauf
Zu Beginn findet eine ausführliche Diagnostik statt, welche Beweglichkeits- und Muskelfunktionstests beinhaltet, um Asymmetrien aufzudecken. Darüber hinaus erfolgt eine Suche nach eventuellen Störfeldern, sowie die Analyse von einigen Hirnnerven um herauszufinden welche zu der Haltungsabweichung geführt haben können.

Im Anschluss werden die Auffälligkeiten gemeinsam besprochen und ein Lösungsansatz erarbeitet. Hier besteht für Sie natürlich auch die Möglichkeit, das Ganze direkt durch eine Haltungskorrektur bearbeiten zu lassen! Weitere Optionen sind die Erarbeitung eines gezielten Kräftigungs- und Mobilisationsprogramms, welches Sie zu Hause durchführen können.
      `}/>
      <Photos photos={[{ photo: photoOne, alt: "Eishockeyprofi Felix Thomas macht Augenliegestütze" }]}/>
      <Text text={`
## Inhalt
Chronische oder akute Schmerzen verringern Ihre Lebensqualität. Sie möchten Hilfe bei der Suche nach den Ursachen sowie eine effektive Behandlung? Ich nehme mir Zeit für Sie und schöpfe aus vielen Jahren Erfahrung in der Schmerztherapie und biete ein breites Spektrum an bewährten und effektiven Therapien. Dabei verbinde ich stets die Anwendungsmethoden aus Physio- und Sporttherapie sowie der traditionellen chinesischen Medizin und neuartiger Therapieverfahren mittels Physiokey und Hypervolt.

Die meisten multimodalen Konzepte zur Schmerzfreiheit fokussieren sich auf den Einsatz von diversen Medikamenten und allgemeinen Trainingstipps. Unleashed Therapy offeriert direkte und individuelle Maßnahmen um akute sowie chronische Schmerzen schnell und effektiv zu bekämpfen und auszuschalten. Ich erläutere, wie Ihre Schmerzen entstehen und wie Sie trotz Schmerzen weiterhin Ihr Training, Beruf und Hobby ausüben können.

Das Wissen, darüber wie manche Dinge besser, effizienter und leichter wären, führt in vielen Fällen zur selbstständigen Handlung. Ich helfe Ihnen dabei und stehe mit all meinen Kompetenzen in Sachen Gesundheit, Ernährung und Stressregulation zur Seite.

__Denn die Arbeit an der Ursache und nicht am Symptom, ist maßgeblich für den Erfolg!__
      `}/>
      <Photos photos={[{ photo: photoTwo, alt: "Behandlung mit dem PhysioKey" }]}/>
    </Layout>
  )
}

export default Therapy
