import { container, copyText, header, text, title, visual } from './header.module.scss'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from "react"
import TextRenderer from '../text/renderer'

const PageHeader = ({ headline, copy, image }) => {
  return (
    <div className={header}>
      <div className={container}>
        <div className={text}>
          <h2 className={title}>{headline}</h2>
          <div className={copyText}><TextRenderer text={copy}/></div>
        </div>
        {( image &&
          <div className={visual}>
            <GatsbyImage image={image.gatsbyImageData} alt={headline}/>
          </div>
        )}
      </div>
    </div>
  )
};

export default PageHeader
